import { registerApplication, start } from "single-spa";
import { constructApplications, constructRoutes, constructLayoutEngine } from "single-spa-layout";

import microfrontendLayout from "./microfrontend-layout.html";

import { getAccessibleApplications } from "@roambee/client-utility";

const data = {
	loaders: {
		hexaLoader: `<Loader></Loader>`
	}
};
const routes = constructRoutes(microfrontendLayout, data);

const applications = constructApplications({
	routes,
	loadApp({ name }) {
		return System.import(name);
	}
});

const layoutEngine = constructLayoutEngine({ routes, applications });

// START : protect route
/** 
	Description:
	(1) Get micro-front-end based on application name. This is the same name used in microfrontend-layout.html file for defining routes
	(2) Set boolean true / false to activeWhen method of micro-front-end to decide whether to show web-app or not
	(3) Inside function get accessible application. This comes from /user/me API response which we call in landing micro-front-end's Home.tsx componenet
	(4) Get application json by checking app_code. If we get the json, it means logged in user has access to that applciation.
	(5) Finally check if we have application json && if the browser url's pathname matching with micro-front-end's route, then allow layout engine to load the micro-fron-end
*/

// cosmos
// const cosmosApp = applications.find((a) => a.name === "@roambee/client-petnet");
// cosmosApp.activeWhen = function (location) {
// 	let accessibleApps = getAccessibleApplications() || [];
// 	let hasAccess = accessibleApps.filter((a) => a.app_code === "cosmos");
// 	return hasAccess.length && location.pathname === "/cosmos";
// };

// // resolve
// const resolveApp = applications.find((a) => a.name === "@roambee/client-resolve");
// resolveApp.activeWhen = function (location) {
// 	let accessibleApps = getAccessibleApplications() || [];
// 	let hasAccess = accessibleApps.filter((a) => a.app_code === "resolve");
// 	return hasAccess.length && location.pathname === "/resolve";
// };

// // revel
// const revealApp = applications.find((a) => a.name === "@roambee/client-reveal");
// revealApp.activeWhen = function (location) {
// 	let accessibleApps = getAccessibleApplications() || [];
// 	let hasAccess = accessibleApps.filter((a) => a.app_code === "reveal");
// 	return hasAccess.length && location.pathname === "/reveal";
// };

// hc20
// const hc20App = applications.find((a) => a.name === "@roambee/client-hc20");
// hc20App.activeWhen = function (location) {
// 	let accessibleApps = getAccessibleApplications() || [];
// 	let hasAccess = accessibleApps.filter((a) => a.app_code === "hc20");
// 	return hasAccess.length && location.pathname === "/hc20";
// };

// integrationhub
// const integrationHubApp = applications.find((a) => a.name === "@roambee/client-integrationhub");
// integrationHubApp.activeWhen = function (location) {
// 	let accessibleApps = getAccessibleApplications() || [];
// 	let hasAccess = accessibleApps.filter((a) => a.app_code === "integrationhub");
// 	return hasAccess.length && location.pathname === "/integrationhub";
// };

// END : protect route

applications.forEach(registerApplication);
layoutEngine.activate();
start();
